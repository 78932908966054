<template>
    <div class="userinfo card card-custom card-stretch w-100">       
        <div class="go-teacher">
            <a class="teacher-help d-flex align-items-center" href="https://fastmind.mx" >
                <img class="mr-2" :src="require('@/assets/images/support-white.svg')">
                <p class="mb-0 mt-1">¿Necesitas ayuda de una maestra?</p>
            </a>
        </div>
        <div class="learning w-100">
            <div class="user-image">
                <div class="useravatar">
                    <router-link
                        to="/avatar">
                        <i class="menu-icon flaticon2-edit"></i>
                    </router-link>
                    <img v-for="item in currentUser.currentAvatar" class="avatar-image" :src="item.file" :key="item._id" :style="`z-index: ${item.zindex-900}`">
                </div>
            </div>
            <div class="w-100 ml-4">
                <div class="d-flex pt-8 pb-4">
                    <div class="input-icon">
                        <input type="text" class="form-control" placeholder="Search..."/>
                        <span><i class="flaticon2-search-1 icon-md"></i></span>
                    </div>
                </div>
                <div class="d-flex py-4 flex-flow-wrap">
                    <a href="#" class="btn mr-2 mb-2" 
                        :class="pageStatus=='home'?'btn-outline-primary':'btn-outline-secondary'"
                        @click="changeStauts('home')"
                    >
                        <i class="fa fa-home"></i> Home
                    </a>
                    <a href="#" class="btn mr-2 mb-2"
                    :class="pageStatus=='in_progress'?'btn-outline-primary':'btn-outline-secondary'"
                    @click="changeStauts('in_progress')"
                    >
                        <i class="fa fa-spinner"></i> In Progress
                    </a>
                    <a href="#" class="btn mr-2 mb-2"
                    :class="pageStatus=='finished'?'btn-outline-primary':'btn-outline-secondary'"
                    @click="changeStauts('finished')"
                    >
                        <i class="fa fa-check"></i> Finished
                    </a>
                    <a href="#" class="btn mr-2 mb-2"
                    :class="pageStatus=='subjects'?'btn-outline-primary':'btn-outline-secondary'"
                    @click="changeStauts('subjects')"
                    >
                        <i class="fa fa-book-open"></i> Subjects
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
@import "@/assets/sass/pages/user/userinfo.scss";
.videodisplay{
    z-index: 100000;
}
.novideodisplay{
    z-index: 9;
}
</style>

<script>

import { mapGetters } from "vuex";

import {
  SET_HOME_DATA
} from "@/core/services/store/data.module.js";

export default{
   props: ['begin','currentVideo'],
   name: "userInfo",
   data() {
    return {
        pageStatus: "home" // 'in_progress', 'finished' , 'subjects'
    }
   },
   created() {
      this.previous = localStorage.getItem('previous');

   },
   mounted(){

   },
   methods:{
    changeStauts(status) {
        this.pageStatus = status;
            this.$emit('changeStatus', status)
       },
       backspace() {
            this.$store.commit(SET_HOME_DATA, {});
            this.$router.go(-1);
       }
   },
   computed: {
        ...mapGetters([
            "currentUser",
            "homeData"
        ]),
   }
}
</script>
